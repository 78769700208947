import React, { Children, useEffect, useState } from 'react';
import { Global, css, ThemeProvider } from '@emotion/react';
// @ts-expect-error no type from this module
import normalize from 'normalize.css';
import 'typeface-open-sans';
import 'typeface-fredericka-the-great';

import { TrackingProvider } from '../../tracking';
import { scale, theme, BREAKPOINTS, baseline } from '../../styled';
import ServicesBlock from '../ServicesBlock';
import RequestQuoteBlock from '../RequestQuoteBlock';
import ReassuranceBlock from '../ReassuranceBlock';
import { buttonStyles } from '../Button';

import Header from './Header';
import Footer from './Footer';
import CookieConsentBanner from './CookieConsentBanner';

const Layout: React.FC<{ isHomePage?: boolean; hasBlocks?: boolean }> = ({
  isHomePage = false,
  hasBlocks = true,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          ${normalize}

          :root {
            --fontSize: ${theme.baseline.m.fontSize};
            --lineHeight: ${theme.baseline.m.lineHeight};

            font-size: calc(var(--fontSize) * 1px);
            line-height: var(--lineHeight);
            font-family: 'Open Sans';
            overflow-x: hidden;

            &.menu-is-opened {
              overflow: hidden;

            }
          }

          @media (min-width: ${BREAKPOINTS.m}) {
            :root {
              --fontSize: ${theme.baseline.l.fontSize};
              --lineHeight: ${theme.baseline.l.lineHeight};
            }
          }

          body {
            ${
              /* istanbul ignore next */ process.env.NODE_ENV === 'development' &&
              `
                background-image: linear-gradient(#eee 1px, transparent 1px);
                background-size: 100% calc(var(--fontSize) * var(--lineHeight) * 1px);
              `
            }
            overflow-x: hidden;
          }

          .menu-is-opened body {
            overflow: hidden;
          }

          h1 {
            font-family: 'Fredericka the great';
            font-weight: normal;
            text-transform: uppercase;
            ${baseline(28, 2, 'm')};

            margin-top: 0;
            margin-bottom: ${scale(2)};

            @media (min-width: ${BREAKPOINTS.m}) {
              ${baseline(51, 3, 'l')};
            }
          }

          h2 {
            ${baseline(25, 2, 'm')};

            margin-top: 0;
            margin-bottom: ${scale(1)};

             @media (min-width: ${BREAKPOINTS.m}) {
              ${baseline(30, 2, 'l')};
            }
          }

          h3 {
            ${baseline(20, 1, 'm')};

            margin-top: 0;
            margin-bottom: ${scale(1)};

            @media (min-width: ${BREAKPOINTS.m}) {
              ${baseline(25, 1, 'l')};
            }
          }

          h4 {
            ${baseline(18, 1, 'm')};

            margin-top: 0;
            margin-bottom: ${scale(1)};

            @media (min-width: ${BREAKPOINTS.m}) {
              ${baseline(20, 1, 'l')};
            }
          }

          p {
            margin-top: 0;
            margin-bottom: ${scale(1)};
          }

          a {
            color: ${theme.color.green.m};
            font-weight: bold;
            transition: color ${theme.timing.s};
            will-change: color;

            &:hover {
              color: ${theme.color.green.s};
            }
          }

          ul {
            margin-top: 0;
            margin-bottom: ${scale(1)};
            padding-left: ${scale(1)};
            list-style: none;

            li {
              position: relative;

              &:before {
                content: '';
                width: ${scale(0.25)};
                height: ${scale(0.25)};
                background-color: ${theme.color.green.m};
                position: absolute;
                display: block;
                border-radius: 50%;
                top: ${scale(0.5)};
                left: ${scale(-0.5)};

                transform: translateY(-50%);
              }

              li {
                &:before {
                  background-color: transparent;

                  border: 1px solid ${theme.color.green.m};
                }
              }
            }
          }

          ol {
            margin-top: 0;
            margin-bottom: ${scale(1)};
            padding-left: ${scale(1)};

            list-style: none;
            counter-reset: ol-counter;

            li {
              position: relative;
              counter-increment: ol-counter;

              &::before {
                content: counter(ol-counter) '.';
                position: absolute;
                display: block;
                ${baseline(12, 1, 'm')};
                border-radius: 50%;
                left: ${scale(-0.75)};
                color: ${theme.color.green.m};

                @media (min-width: ${BREAKPOINTS.m}) {
                  ${baseline(14, 1, 'l')};
                }
              }
            }
          }

          blockquote {
            position: relative;
            margin: 0 ${scale(1)} ${scale(2)};
            padding-top: ${scale(4)};
            ${baseline(18, 1, 'm')};
            font-style: italic;
            text-align: center;

            @media (min-width: ${BREAKPOINTS.m}) {
              ${baseline(22, 1, 'l')};
            }

            &:before {
              position: absolute;
              content: '';
              display: block;
              left: 50%;
              top: 0;
              transform: translateX(-50%) rotate(180deg);
              width: ${scale(3.75)};
              height: ${scale(3.75)};
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fill="${theme.color.green.m.replace(
                '#',
                '%23'
              )}" d="M91.2 25.3c-2.8-4.5-7.1-7.5-12.3-8.4-5.4-1-11.1.4-15.5 3.8C59 24 56.2 29 55.7 34.4c-.5 5.4 1.1 10.6 4.6 14.7 2.9 3.4 6.7 5.7 10.9 6.6l-1.6 25.2.2.5c.4 1.1 1.4 1.8 2.5 1.9h.2c1.1 0 2.1-.6 2.5-1.5.9-1.8 2-3.8 3.2-6.1 5.4-10.1 12.7-24 15.1-31.8 1.9-6.4 1.2-13.3-2.1-18.6zm-3.1 16.9c-1.9 6.2-7.3 16.9-12.1 26l1-17.4-2.7-.2c-3.8-.3-7.3-2.1-9.8-5-2.5-2.9-3.7-6.7-3.3-10.6.4-3.9 2.3-7.4 5.5-9.8 2.6-2 5.7-3 8.8-3 .8 0 1.6.1 2.4.2 4.6.8 7.2 3.6 8.6 5.8 2.5 3.9 3 9.2 1.6 14zM28.9 17c-5.4-1-11.1.4-15.5 3.8s-7.2 8.3-7.7 13.7c-.5 5.4 1.1 10.6 4.6 14.7 2.9 3.4 6.7 5.7 10.9 6.6L19.6 81l.2.5c.4 1.1 1.4 1.8 2.5 1.9h.2c1.1 0 2-.6 2.5-1.5.9-1.8 2-3.8 3.2-6.1 5.4-10.1 12.7-24 15.1-31.8 1.9-6.3 1.1-13.2-2.1-18.5-2.7-4.7-7.1-7.6-12.3-8.5zm9.2 25.2c-1.9 6.2-7.3 16.9-12.1 26l1-17.4-2.7-.2c-3.8-.3-7.3-2.1-9.8-5-2.5-2.9-3.7-6.7-3.3-10.6.4-3.9 2.3-7.4 5.5-9.8 2.6-2 5.7-3 8.8-3 .8 0 1.6.1 2.4.2 4.6.8 7.2 3.6 8.6 5.8 2.5 3.9 3 9.2 1.6 14z"/></svg>');
            }
          }

          pre {
            display: block;
            overflow: auto;
            max-width: 100%;
            ${baseline(14, 1, 'm')};
            font-family: monospace;
            word-wrap: break-word;
            page-break-inside: avoid;
            margin-top: 0;
            margin-bottom: ${scale(2)};
            padding: ${scale(1)};
            padding-left: ${scale(0.75)};
            border-left: ${scale(0.25)} solid ${theme.color.green.m};
            background-color: ${theme.color.gray.s};

            @media (min-width: ${BREAKPOINTS.m}) {
              ${baseline(16, 1, 'l')};
            }
          }

          button {
            ${buttonStyles({ theme })};
          `}
      />
      <TrackingProvider>
        <div
          css={css`
            min-height: 100vh;
            display: grid;
            grid-template-rows: 1fr auto;
            grid-template-columns: 100%;
          `}
        >
          <Header isHomePage={isHomePage} />
          <main
            css={css`
              padding-top: ${scale(4)};

              @media (min-width: ${BREAKPOINTS.m}) {
                padding-top: ${isHomePage ? '0' : scale(8)};
              }
            `}
          >
            {children}
            {hasBlocks && (
              <React.Fragment>
                <ServicesBlock data-testid="block-services" />
                <RequestQuoteBlock data-testid="block-request-quote" />
                <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
              </React.Fragment>
            )}
          </main>
          <Footer />
          <CookieConsentBanner />
        </div>
      </TrackingProvider>
    </ThemeProvider>
  );
};

export default Layout;
