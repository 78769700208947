export function validateEmail(email: string): boolean {
  /* Regular expression from https://emailregex.com/ */
  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegEx.test(email);
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  /* Regular expression from https://stackoverflow.com/questions/38483885/regex-for-french-telephone-numbers */
  const phoneNumberRegEx = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

  return phoneNumberRegEx.test(phoneNumber);
}

export function urlEncode(data: { [key: string]: string }): string {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}
