import React from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import { SerializedStyles } from '@emotion/react';

import styled, { scale, BREAKPOINTS, hachureStyles } from '../../styled';
import Button, { ButtonVariant } from '../Button';
import { useTrackingContext } from '../../tracking';

const Layout = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${scale(1)};

  background-color: #fff;

  &:before {
    content: '';
    ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

const Actions = styled.div`
  display: flex;
  margin: ${scale(-0.5)};
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINTS.m}) {
    flex-wrap: initial;
  }

  > * {
    width: 100%;
    margin: ${scale(0.5)};

    @media (min-width: ${BREAKPOINTS.m}) {
      width: 50%;
    }
  }
`;

const CTA = styled(Button)`
  width: 100%;
  justify-content: center;
`;

const cookieName = 'CookieConsent';

let hasBeenRejected = false;

export function hasCookieConsented(): boolean {
  return Cookies.get(cookieName) === 'true';
}

const CookieConsentBanner: React.FC = (props) => {
  const { tag } = useTrackingContext();
  const [isVisible, setVisibility] = React.useState(false);

  const handleCookieConsent = React.useCallback(() => {
    /* istanbul ignore else */
    if (tag)
      tag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    Cookies.set(cookieName, 'true', { secure: true, sameSite: 'strict', expires: 90 });
    setVisibility(false);
  }, [setVisibility, tag]);

  const handleHidingBanner = React.useCallback(() => {
    setVisibility(false);
    hasBeenRejected = true;
  }, [setVisibility]);

  React.useEffect(() => {
    if (!hasBeenRejected) setVisibility(!hasCookieConsented());
  }, []);

  return (
    <React.Fragment>
      {isVisible && (
        <Layout {...props} aria-live="assertive" data-testid="cookies-consent-banner">
          <p>
            Nous nous soucions de vos données, et nous n&apos;utiliserions des cookies que pour
            améliorer votre expérience.{' '}
            <Link to="/politique-confidentialite">Politique de confidentialité</Link>.
          </p>
          <Actions>
            <CTA onClick={handleCookieConsent}>Ok !</CTA>
            <CTA variant={ButtonVariant.OUTLINED} onClick={handleHidingBanner}>
              Non, merci.
            </CTA>
          </Actions>
        </Layout>
      )}
    </React.Fragment>
  );
};

export default React.memo(CookieConsentBanner);
